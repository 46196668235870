<template>
  <div>

    <v-sheet color="white" class="pa-10">
      <v-btn class="float-right mb-9" icon @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
      <h1 class="text-h3 pt-16 font-weight-medium text-center">Додати Новий Шаблон</h1>
      <h2 style="line-height: 1.2" class="mt-3 font-weight-light text-center">Введіть назву та аліас нового твору мистецтва </h2>
      <div class="mt-16 px-6">
        <v-text-field rounded filled dense v-model="addData.name" placeholder="Назва бізнесу" label="Назва" required/>
        <v-text-field rounded filled dense v-model="addData.alias" placeholder="Постійне посилання" label="Посилання" required>
          <template v-slot:append>
            <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
          </template>
        </v-text-field>


        <!-- Navigation-->
        <div class="d-flex justify-end mt-10 mb-6">
          <v-btn :loading="loading" :disabled="loading"  @click="closeDialog"
                 large class="mt-3 px-8 " text rounded elevation="0" color="blue darken-2" dark>
            <h3 class="font-weight-light">Назад</h3>
          </v-btn>
          <v-btn :disabled="loading"  @click="addTemplate"
                 large class="mt-3 px-8 " rounded filled elevation="0" color="blue darken-2" dark>
            <h3 class="font-weight-light">Додати</h3>
          </v-btn>
        </div>
      </div>
    </v-sheet>



  </div>
</template>

<script>

import {mapActions} from "vuex";

export default {
  name  : "addTemplate",
  props : ['value'],
  data(){
    return {
      loading : false,

      addData : {}
    }
  },
  methods : {
    ...mapActions('templates',  [ 'ADD_TEMPLATE' ]),
    closeDialog(){
      this.$emit('input',!this.value)
    },
    addTemplate() {
      this.loading = true

      this.ADD_TEMPLATE(this.addData).then((out)=>{

        console.log(out)
        this.loading = false
        this.$emit('onFinish',this.addData)
        this.closeDialog()

      }).catch(()=>{
        this.notify('Something went wrong')
        this.loading = false
      })

    }
  }
}
</script>

<style scoped>

</style>