<template>
  <div>
    <card>
      <template slot="header">
        <v-btn outlined rounded dark @click="back">
          <v-icon>mdi-chevron-left</v-icon>
          Back</v-btn>
        <h3>Редагувати шаблон</h3>
      </template>
      <h1 class="text-h3 pt-6 font-weight-medium ">Шаблон {{ template.name }} </h1>

      <v-simple-table>
        <tbody>
        <tr v-for="(item,i) in items" :key="i">
          <td >
            <div v-if="!item.edit">
              {{  item.title  }}
            </div>
            <div v-else>
              <v-text-field label="Name" v-model="item.title"></v-text-field>
            </div>
          </td>
          <td>
          <td width="200px">
            <div v-if="!item.edit">
              {{  item.name  }}
            </div>
            <div v-else>
              <v-text-field label="Name" v-model="item.name"></v-text-field>
            </div>
            </td>
          <td>
            <div v-if="!item.edit">
              {{  item.type  }}
            </div>
            <div v-else>
              <v-text-field label="Type" v-model="item.type"></v-text-field>
            </div>
          </td>
          <td width="200px">
            <div v-if="!item.edit">
              {{  item.params  }}
            </div>
            <div v-else>
              <v-text-field label="Name" v-model="item.params"></v-text-field>
            </div>
          </td>
          <td>
          <td>
            <div v-if="!item.edit">
              <v-btn @click="editVar(i)"  icon plain color="blue darken-2"><v-icon>mdi-pencil</v-icon></v-btn>
            </div>
            <div v-else class="d-flex">
              <v-btn @click="saveVar(i)" icon plain color="green "><v-icon>mdi-content-save</v-icon></v-btn>
              <v-btn @click="closeEdit(i)" icon plain color=""><v-icon>mdi-close</v-icon></v-btn>
            </div>


          </td>
        </tr>
        <tr>
          <td colspan="2">
            <v-btn icon plain color="green" @click="newVar"><v-icon>mdi-plus</v-icon></v-btn>
          </td>
        </tr>
        </tbody>
      </v-simple-table>


    </card>
  </div>
</template>

<script>
import card from "@/components/pages/dashboard/UI/card";
import {mapActions} from "vuex";
export default {
  name       : "editTemplate",
  props      : ['uuid','value'],
  components : {
    card
  },
  data() {
    return {
      template : {},
      items : [],
    }
  },
  methods : {
    ...mapActions('templates',  [ 'GET_TEMPLATE','EDIT_TEMPLATE' ]),
    newVar(){
      this.items.push({name:'newvar',type:'1',title:'newvar',params: {},edit:true})
    },
    editVar(index){

      let temp = this.items[index]
      temp.edit = true
      this.$set(this.items,index,temp)

    },
    saveVar(index){

      this.loading = true;
      this.EDIT_TEMPLATE({uuid:this.template.id,data:this.items[index]}).then(()=>{
       this.notify('New Variable added')
        this.loading = false
      }).catch(()=>{this.loading = false})

      let temp = this.items[index]
      temp.edit = false
      this.$set(this.items,index,temp)
    },
    closeEdit(index){
      let temp = this.items[index]
      temp.edit = false
      this.$set(this.items,index,temp)
    },

    back(){
      this.$emit('input',!this.value)
    },

  },
  mounted(){
    this.loading = true;
    this.GET_TEMPLATE(this.uuid).then((out)=>{
      this.template = out.data
      this.template.items.forEach((item)=>{
        item.edit = false
        this.items.push(item)
      })
      this.loading = false
    }).catch(()=>{this.loading = false})
  }
}
</script>

<style scoped>

</style>